import React from "react";
import { Helmet } from "react-helmet";
import {LinkWithUTM as Link} from '../../components/LinkWithUTM'
import { StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import CqcApproved from '../../components/CqcApproved'

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import "../../../scss/main.scss";
import Trustpilot from "../../components/Trustpilot/Trustpilot";

import {
	ConditionSplash,
	ConditionDescription,
	ConditionDescriptionRightPanel,
	ConditionDescriptionRightPanelParagraph,
	ConditionInfographicSection,
	ConditionInfographicSectionIntro,
	ConditionInfographicMainContent,
	ConditionInfographicDescription,
	ConditionInfographicDescriptionParagraph,
	ConditionsInforgraphicTypes,
	ConditionsInfographicTypesHeading,
	ConditionsInfographicTypesDetail,
	ConditionsInfographicTypesDetailSection,
	ConditionsInfographicSymptons,
	ConditionsInfographicSymptonsHeader,
	ConditionsInfographicSymptonDiagram,
	ConditionsInfographicSymptonDiagramMainCopy,
	ConditionsInfographicSymptonDiagramTopics,
	ConditionsInfographicSymptonDiagramTopic,
	ConditionsInfographicSymptonDiagramTopicHeading,
	ConditionsInfographicSymptonDiagramTopicCopy,
	ConditionStats,
	ConditionStatsMainContent,
	ConditionStat,
	ConditionStatCopy,
	ConditionStatCopyInline,
	ConditionStatCopyEmph,
	ConditionStatCopyEmphInline,
	ConditionLivein,
	ConditionLiveinHeader,
	ConditionLiveinHeaderCopy,
	ConditionLiveinHomeCard,
	ConditionLiveinHomeCardMainCopy,
	ConditionLiveinHomeCardMainTip,
	ConditionLiveinHomeCardMainTipTitle,
	ConditionLiveinHomeCardMainTipCopy,
	ConditionTrainingCard,
	ConditionTrainingCardMainCopy,
	ConditionTrainingCardTip,
	ConditionTrainingCardTipItem,
} from '../../components/Conditions'

export default function Dementia() {
	const dementiaBackgroundFluid = useStaticQuery(graphql`
		query {
			background: file(relativePath: {eq: "dementia-image-02.jpg"}){
				childImageSharp {
					fluid(quality: 90) {
					  ...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`).background.childImageSharp.fluid
	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Parkinson’s care at home</title>
				<meta property="og:title" content="Edyn Care | Parkinson’s care at home"/>
				<meta property="og:description" content="High quality care in the comfort of your own home."/>
				<meta property="og:image" content="https://www.edyn.care/assets/marketing-site/images/condition_parkinsons_splash.jpeg"/>
				<meta property="og:url" content="https://www.edyn.care/condition/parkinsons" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta property="twitter:domain" content="edyn.care" />
				<meta property="twitter:url" content="https://www.edyn.care/condition/parkinsons" />
				<meta name="twitter:title" content="Edyn Care | Parkinsons" />
				<meta name="twitter:description" content="High quality care in the comfort of your own home." />
				<meta name="twitter:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
			</Helmet>

			<div className="container">
				<Header />
				<main>
					<ConditionSplash>
						<StaticImage 
							src="../../../static/assets/marketing-site/images/circle-dementia.svg" 
							placeholder="blurred"
						/>
						<h3>Parkinson’s care at home</h3>
					</ConditionSplash>
					<ConditionDescription>
						<StaticImage 
							src="../../../static/assets/marketing-site/images/condition_parkinsons_splash.jpeg"
							placeholder="blurred"
						/>
						<ConditionDescriptionRightPanel>
							<h3>
								Having Parkinson’s can feel overwhelming if you don’t have the right information and support. 
							</h3>
							<ConditionDescriptionRightPanelParagraph>
								It’s normal to be worried when someone you love gets diagnosed with Parkinson’s. The fear of the unknown can be unsettling. Despite this, it’s vital to remain positive. Thanks to ongoing research, live-in care techniques and assistive technology, it is possible to live well with Parkinson’s, and remain at home. 
							</ConditionDescriptionRightPanelParagraph>
							<ConditionDescriptionRightPanelParagraph>
								At Edyn we know first hand the power of having familiar surroundings. We hope, therefore, to lighten the burden and help you through the experience by sharing some useful information that will enable you to understand the disease a little better.
							</ConditionDescriptionRightPanelParagraph>
						</ConditionDescriptionRightPanel>
					</ConditionDescription>
					<ConditionInfographicSection>
						<ConditionInfographicSectionIntro>
							<h1>What is Parkinson’s?</h1>
							<p className="secondary pro">
								Parkinson’s is a neurological disease in which parts of the brain become progressively damaged over many years. 
							</p>
						</ConditionInfographicSectionIntro>
						<ConditionInfographicMainContent>
							<ConditionInfographicDescription>
								<h3>
								There are many different types of Parkinsons’
								</h3>
								<ConditionInfographicDescriptionParagraph>
									It is progressive, meaning that symptoms will eventually get worse. It is caused by a loss of nerve cells in part of the brain called the substantia nigra. This leads to a reduction in a chemical called dopamine in the brain. Dopamine plays a vital role in regulating the movement of the body. A reduction in dopamine is responsible for many of the symptoms of Parkinson’s disease. Exactly what causes the loss of nerve cells is unclear. Most experts think that combination of genetic and environmental factors is responsible. 
								</ConditionInfographicDescriptionParagraph>
							</ConditionInfographicDescription>
							<ConditionsInforgraphicTypes>
								<ConditionsInfographicTypesHeading>
									Some prominent types of Parkinson’s are:
								</ConditionsInfographicTypesHeading>
								<ConditionsInfographicTypesDetail>
									<ConditionsInfographicTypesDetailSection title={`Idiopathic Parkinson’s`}>
										<ul>
											<li>
												Idiopathic means the cause is unknown.
											</li>
											<li>
												The most common symptoms of idiopathic Parkinson’s are tremor, rigidity and slowness of movement. 
											</li>
										</ul>
									</ConditionsInfographicTypesDetailSection>
									<ConditionsInfographicTypesDetailSection title={`Vascular parkinsonism`}>
										<ul>
											<li>
												Vascular parkinsonism (also known as arteriosclerotic parkinsonism) affects people with restricted blood supply to the brain. Sometimes people who have had a mild stroke may develop this form of parkinsonism.
											</li>
											<li>
												Common symptoms include problems with memory, sleep, mood and movement.
											</li>
										</ul>
									</ConditionsInfographicTypesDetailSection>
									<ConditionsInfographicTypesDetailSection title={`Drug-induced parkinsonism`}>
										<ul>
											<li>
												Some drugs can cause parkinsonism.
											</li>
											<li>
												Neuroleptic drugs (used to treat schizophrenia and other psychotic disorders), which block the action of the chemical dopamine in the brain, are thought to be the biggest cause of drug-induced parkinsonism. 
											</li>
											<li>
												The symptoms of drug-induced parkinsonism tend to stay the same – only in rare cases do they progress in the way that Parkinson’s symptoms do.
											</li>
											<li>
												Drug-induced parkinsonism only affects a small number of people, and most will recover within months – and often within days or weeks – of stopping the drug that’s causing it.
											</li>
										</ul>
									</ConditionsInfographicTypesDetailSection>
									<ConditionsInfographicTypesDetailSection title={`Multiple system atrophy (MSA)`}>
										<ul>
											<li>
												Like Parkinson’s, MSA can cause stiffness and slowness of movement in the early stages.
											</li>
											<li>
												However, people with MSA can also develop symptoms that are unusual in early Parkinson’s, such as unsteadiness, falls, bladder problems and dizziness.
											</li>
										</ul>
									</ConditionsInfographicTypesDetailSection>
								</ConditionsInfographicTypesDetail>
							</ConditionsInforgraphicTypes>

							<ConditionsInfographicSymptons>
								<ConditionsInfographicSymptonsHeader>
								Telling the difference between types of parkinsonism isn’t always easy as the first symptoms of the different forms of Parkinsonism are so similar. In many cases, parkinsonism develops gradually. Most people with Parkinson’s start to develop symptoms when they’re over 50. 
								</ConditionsInfographicSymptonsHeader>
								<ConditionsInfographicSymptonDiagram>
									<ConditionsInfographicSymptonDiagramMainCopy>
										Everyone with Parkinsonism is different and has different symptoms. There are four main symptoms of Parkinson’s disease.
									</ConditionsInfographicSymptonDiagramMainCopy>
									<ConditionsInfographicSymptonDiagramTopics>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>Tremor:</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												Shaking, which usually begins in the hand or arm and is likely to occur when the limb is relaxed and resting.
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>Slowness of movement:</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												This is known as bradykinesia which is when physical movements are much slower than normal, which can make everyday tasks difficult and result in a distinctive slow, shuffling walk with very small steps.
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>
												Muscle stiffness:
											</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												Stiffness and tension in the muscles, which can make it difficult to move around and make facial expressions, and can result in painful muscle cramps.
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>
												Loss of sense of smell:
											</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												Sometimes occurs several years before other symptoms develop.
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
									</ConditionsInfographicSymptonDiagramTopics>
								</ConditionsInfographicSymptonDiagram>
							</ConditionsInfographicSymptons>
						</ConditionInfographicMainContent>
					</ConditionInfographicSection>
					<ConditionStats>
						<BackgroundImage className="f-c-image" fluid={dementiaBackgroundFluid}>
							<h1>Parkinson’s facts and stats</h1>
						</BackgroundImage>
						<ConditionStatsMainContent>
							<ConditionStat>
								<ConditionStatCopyEmph>145,000</ConditionStatCopyEmph>
								<ConditionStatCopy>
									people in the UK living with Parkinson’s
								</ConditionStatCopy>
								{/*   <hr>  */}
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopyInline>Men are</ConditionStatCopyInline>
								<ConditionStatCopyEmphInline>1.4</ConditionStatCopyEmphInline>
								<ConditionStatCopy>
									times more likely to have Parkinsons than women
								</ConditionStatCopy>
								{/*   <hr>  */}
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopy>
									2 people diagnosed with Parkinson’s 
								</ConditionStatCopy>
								<ConditionStatCopyEmph>Every hour</ConditionStatCopyEmph>
								{/*   <hr>  */}
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopyEmph>1 in 37</ConditionStatCopyEmph>
								<ConditionStatCopy>people alive in the UK will be diagnosed with Parkinson’s in their lifetime</ConditionStatCopy>
								{/*   <hr>  */}
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopyEmph>14%</ConditionStatCopyEmph>
								<ConditionStatCopy>
									of the social care budget is spent on people with neurological conditions
								</ConditionStatCopy>
								{/*   <hr>  */}
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopyEmph>We don't yet know</ConditionStatCopyEmph>
								<ConditionStatCopy>exactly why people get Parkinson’s</ConditionStatCopy>
								{/*   <hr>  */}
							</ConditionStat>
						</ConditionStatsMainContent>
					</ConditionStats>
					<ConditionLivein>
						<ConditionLiveinHeader>
							<h1>Parkinson’s live-in care</h1>
							<ConditionLiveinHeaderCopy>
								We understand that with patience, trust and genuine care, we
								can help those diagnosed with Parkinson's maintain a high quality
								of life and live comfortably in their own home.
							</ConditionLiveinHeaderCopy>
						</ConditionLiveinHeader>
						<ConditionLiveinHomeCard title={`Ensuring your home is Parkinson’s friendly`}>
							<ConditionLiveinHomeCardMainCopy>
								When considering live-in care it’s important to ensure that the home of the care recipient is safe and secure. Does it compensate for any difficulties they are experiencing? Does it keep them safe? Does it support their mobility?
								<br />
								<br />
								At Edyn as part of our approach we complete a home risk assessment for all of our clients. Our care managers ensure that your home is the best possible setting for care. Small changes can make big differences. 
							</ConditionLiveinHomeCardMainCopy>
							<ConditionLiveinHomeCardMainTip>
								<ConditionLiveinHomeCardMainTipTitle>Top Parkinson's care tip:</ConditionLiveinHomeCardMainTipTitle>
								<br />
								<ConditionLiveinHomeCardMainTipCopy>
									Monitoring “on” and “off” periods can be helpful to understand individuals movement during those times. This can help adjust treatment and care recommendations
								</ConditionLiveinHomeCardMainTipCopy>
							</ConditionLiveinHomeCardMainTip>
						</ConditionLiveinHomeCard>
						<ConditionTrainingCard title={`Professional carers trained specifically for Parkinson’s:`}>
							<ConditionTrainingCardMainCopy>
								Our professional care teams receive on-going training, mentoring and support based on the latest thinking and research that ensure they have the skills to deliver positive Parkinson’s care. This includes:
							</ConditionTrainingCardMainCopy>
							<ConditionTrainingCardTip>
								<ConditionTrainingCardTipItem>Managing and administering medications</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Ensuring safe mobility in and around the home</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Maintaining exercise</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Sensory and dental care</ConditionTrainingCardTipItem>
							</ConditionTrainingCardTip>
						</ConditionTrainingCard>
					</ConditionLivein>
					{/* <section>
						<div className="customer-say">
							<div className="customer-words">
								<p className="secondary">What our customers say:</p>
								<div className="customer-words-group">
									<h3 className="text-with-quotes">
										<span className="customer-words-group__quotes">“</span>
										What makes Edyn stand out from other agencies we have used
										for live-in care is the fact that they care - the human
										touch is there.
										<span className="customer-words-group__quotes">”</span>
									</h3>
								</div>
							</div>
							<div className="customer-images">
								<div className="customer-images-bubbles">
									<StaticImage placeholder="blurred" src="../../../static/assets/marketing-site/images/dementia-quote-customers1.png" />
									<StaticImage placeholder="blurred" src="../../../static/assets/marketing-site/images/dementia-quote-customers2.png" />
								</div>
								<div className="customer-names">
									<p className="secondary">
										Sarah M.
										<br />
										<span>Mum</span>
									</p>
									<p className="secondary">
										Julie M.
										<br />
										<span>Daughter</span>
									</p>
								</div>
							</div>
						</div>
					</section> */}

					<section className="live-in dementia">
						<h1 className="live-in-heading">
							Why choose live-in care with Edyn
						</h1>
						<div className="reasons">
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../../static/assets/marketing-site/images/proffesional-carer.png"
									alt="proffesional carers"
								/>
								<h3>First class professional carers</h3>
								<p className="primary">
									Each one of our carers is trained in house by our care support
									team.
								</p>
							</div>
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../../static/assets/marketing-site/images/support-teams.png"
									alt="Support Teams"
								/>
								<h3>Support teams to manage your care.</h3>
								<p className="primary">
									Our dedicated care specialists will handle the progression of
									your care.
								</p>
							</div>
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../../static/assets/marketing-site/images/technology.png"
									alt="Technology"
								/>
								<h3>Technology to keep everyone updated</h3>
								<p className="primary">
									Stay informed and up to date with our smart online portal.
								</p>
							</div>
						</div>
						<Link to="/approach/" className="button-link">
							<button className="btn-primary">
								Learn more about our process
							</button>
						</Link>
					</section>
					{/* <Trustpilot /> */}
					<CqcApproved />
				</main>
				<Footer />
			</div>
		</>
	);
}
